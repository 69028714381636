.rootLogin {
  background-color: white;
  height: 100vh;
}

.loginFlex {
  display: flex;
  align-items: center;
}
.rootLogin .loginContainer {
  padding: 9% 70px;
  background-color: white;
}

.login-input {
  max-width: 275px;
}

.rootLogin .toggleBtn {
  display: none !important;
}

.rootLogin h1 {
  font-family: var(--fuenteBold);
  font-size: 44px;
  color: var(--color-accent);
  /* padding: 6px 0px; */
  margin: 6px 0px;
}

.rootLogin h2 {
  font-family: var(--fuenteBlack);
  font-size: 34px;
  color: var(--color-accent);
  margin: 0px;
  margin: 6px 0px;
}

.rootLogin label {
  font-family: var(--fuenteLight);
  font-size: 15px;
}

.rootLogin #btnInicioSesion {
  margin-top: 10px;
  padding-top: 4px;
  background: var(--color-primary);
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: white;
  width: 275px;
  height: 40px;
  border-radius: 10px;
  border: 0ch;
}

.rootLogin .input {
  display: block;
  padding-left: 15px;
  margin-bottom: 10px;
  margin-top: 3px;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 16px;
  width: 256px;
  height: 35px;
  border-style: solid;
}

.rootLogin .links {
  text-align: center;
  width: 260px;
  display: block;
}

.rootLogin h5 {
  font-family: var(--fuenteLight);
  font-size: 13px;
  font-weight: lighter;
}

.rootLogin h5 a {
  color: var(--color-primary);
}

.rootLogin i {
  font-size: 24px;
  color: var(--color-primary);
  font-style: normal;
  margin: 4px;
}

.rootLogin .img-login {
  width: 100%;
  height: 100%;
}


/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) {
  .rootLogin .loginContainer {
    padding: 70px 50px;
  }

  .login-input {
    max-width: inherit;
  }

  .reverse {
    order: -1;
  }

  .login-item {
    text-align: center;
  }

  .rootLogin .inputsContainer {
    display: grid;
    justify-items: center;
  }

  .rootLogin h1 {
    font-size: 33px;
    margin-bottom: 20;
  }

  .rootLogin h2 {
    font-size: 26px;
  }

  .rootLogin .links {
    width: inherit;
  }

  .rootLogin .nav {
    display: flex;
    opacity: initial;
    background-color: white;
  }

  .rootLogin .nav-ul {
    color: var(--color-primary);
    display: flex;
    align-items: center;
  }
}

/*landscape*/
@media (min-width: 992px) and (max-width: 1265px) {
  .rootLogin h1 {
    font-size: 38px;
    margin-bottom: 20;
  }

  .rootLogin h2 {
    font-size: 30px;
  }
}

/*fin tablet*/

/*movil*/

@media (max-width: 575.98px) {
  .login-item {
    text-align: center;
  }

  .rootLogin .links {
    width: inherit;
  }

  .login-input {
    max-width: inherit;
  }

  /* 
  .login-item {
    padding:0px !important
  } */

  .rootLogin .loginContainer {
    padding: 50px 20px;
  }

  .rootLogin h1 {
    text-align: center;
    font-size: 26px;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .rootLogin .img-login {
    order: -1;
    margin-top: 15px;
  }

  .rootLogin .inputsContainer {
    display: grid;
    justify-items: center;
  }

  .rootLogin h2 {
    font-family: var(--fuenteLight);
    font-size: 22px;
  }
  .reverse {
    order: -1;
  }

  .rootLogin .nav {
    display: flex;
    opacity: initial;
    justify-content: center;
    background-color: white;
  }

  .rootLogin .nav-ul {
    color: var(--color-primary);
    display: flex;
    align-items: center;
  }

  .rootLogin .nav-ul li {
    font-size: 13px;
  }
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn :hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn :active {
  background-color: #eeeeee;
}

.login-with-google-btn :focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

/*fin movil*/
