@font-face {
  font-family: "circularBold";
  src: url("./fonts/stdBook.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "circularMedium";
  src: url("./fonts/stdMedium.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "circularBook";
  src: url("./fonts/stdBook.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "circularBlack";
  src: url("./fonts/stdBlack.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "material-icons";
  src: url("./fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "material-iconsO";
  src: url("./fonts/MaterialIconsOutlined-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "material-iconsR";
  src: url("./fonts/MaterialIconsRound-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "material-iconsS";
  src: url("./fonts/MaterialIconsSharp-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "material-iconsT";
  src: url("./fonts/MaterialIconsTwoTone-Regular.ttf") format("truetype");
}

:root {
  --color-primary: #006aea;
  --color-secondary: #9cc8ff;
  --color-bg-variant: #d1d3d4;
  --color-accent: #00479b;

  --fuenteMedium: "circularMedium";
  --fuenteBold: "circularBold";
  --fuenteLight: "circularBook";
  --fuenteBlack: "circularBlack";

  --admHeaderSidebar: #f5f5f6;
  --adm-bg: white;
  --color-bg: #eef1f6;

  /* --admHeaderSidebar: white;
  --adm-bg: #f4f6fa;
  --color-bg: #f0f0f1; */
}

.principal {
  margin: 210px 285px 0px 62px;
  box-sizing: border-box;
  height: 100vh;
}

.principal.d-flex {
  justify-content: space-around;
}

.text-center {
  text-align: center;
}
.note-modal-backdrop {
  position: inherit !important;
}
.checkbox input {
  display: inherit;
}
.signer {
  position: relative;
  right: 0px;
}

.signer-button-container {
  position: absolute;
  bottom: -18px;
  width: fit-content;
  right: -12px;
}

.signer canvas {
  width: 100%;
  height: 100%;
  padding-left: 0;
}

.signer-button-container button {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
  margin-right: 15px;
  cursor: pointer;
}

.signer-button-container button i {
  color: #555555;
}

.signer .accept-button {
  box-shadow: 0 4px 8px -3px #33ff33 !important;
  animation: focusBtn 1.6s 0s;
  animation-iteration-count: infinite;
}

.signer .quit-button {
  position: absolute;
  right: 5px;
  top: 5px;
  background: white;
  border-radius: 50%;
  border: none;
  color: #c8b2b2;
}

@keyframes focusBtn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.error-container {
  height: 100vh;
}

.img-error-container {
  height: 60%;
}

.img-error-container + .message {
  height: auto;
  font-size: 1.2rem;
  font-family: var(--fuenteBold);
  color: var(--color-accent);
  text-align: center;
}

.img-error-container img {
  min-height: 100%;
}

.material-icons {
  font-family: "material-icons";
  font-size: 22px;
  font-style: normal;
}

.material-icons-outlined {
  font-family: "material-iconsO";
  font-size: 22px;
  font-style: normal;
}

.table-noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.page-root {
  background: var(--color-bg);
  height: 100vh;
}

.page-container {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-title-container {
  margin-bottom: 35px;
  text-align: center;
  padding: 0px 10px;
}

.page-title-item {
  color: #00489b;
  font-size: 2rem !important;
  font-family: var(--fuenteMedium);
  margin-bottom: 14px;
}

.page-title-container > span {
  display: block;
  margin-bottom: 4px;
  font-size: 1.2rem;
  font-family: var(--fuenteLight);
  color: #063974;
}

.page-button {
  background: #00489b;
  color: white;
  border: none;
  border-top: cornsilk;
  padding: 6px 34px;
  border-radius: 10px;
}

.page-button:active {
  color: #b9c4d3;
}

.page-image img {
  width: 100%;
}

.text-red {
  color: #e74c3c !important;
}

.text-grey {
  color: gray !important;
}

.bg-grey {
  background-color: #e2e2e2 !important;
  color: #9a9a9a !important;
}

.text-primary {
  color: var(--color-primary) !important;
}

.main-admin {
  padding: 50px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--adm-bg);
}

.modal-info-text {
  font-family: "circularMedium";
  color: var(--color-primary);
  font-size: 14px;
  display: block;
  margin: 10px;
  margin-bottom: 4px;
}

.button-text {
  border-radius: 7px;
  font-family: var(--fuenteMedium);
  color: var(--color-primary);
  border: none;
  background: transparent;
  padding: 10px;
  font-size: 14px;
}

.button-text label {
  cursor: pointer;
}

.relative-container {
  position: relative;
  width: 120px;
  height: 124px;
  margin: auto;
}

.modal-imagecontainer {
  position: fixed;
  width: 120px;
  margin: auto;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

.estudios-title {
  display: flex;
}

.estudios-title label {
  align-self: center;
  margin-bottom: 0px !important;
}

.estudios-title button {
  margin-top: 4px;
}

.estudios-title button i {
  font-size: 20px;
}

.bounce-5 {
  animation-name: bounce-5;
  animation-timing-function: ease;
}

@keyframes bounce-5 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-75px);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.modal-imagecontainer img {
  width: inherit;
}

.body-title {
  font-family: var(--fuenteMedium);
  font-size: 28px;
  color: #00479b;
  display: block;
  text-align: center;
}

.body-text {
  font-family: var(--fuenteMedium);
  font-size: 16px;
  color: #696969;
  display: block;
  text-align: center;
}

.button-text:active {
  background: transparent;
  color: #373737;
}

.small {
  width: 25%;
  min-width: 250px;
}

.medium {
  width: 50%;
  min-width: 250px;
}

.large {
  width: 75%;
  min-width: 250px;
  max-width: 1400px;
}

.full-width {
  width: 100%;
}

.full-screen {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  position: relative;
}

.error {
  color: #dc2525 !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  font-size: 12px !important;
  font-family: var(--fuenteLight);
  margin-left: 4px;
}

.success {
  color: #56ba3e !important;
}

.success-d {
  color: #1a7b43 !important;
}

.danger {
  color: #dc2525;
}

.danger-d {
  color: #8f2424;
}

body {
  margin: 0px;
}

.justify-self-center {
  justify-self: center;
}

.align-self-center {
  align-self: center;
}

.pt-12 {
  padding-top: 12px;
}

.ma-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-1 {
  margin-left: 1%;
}
.ml-2 {
  margin-left: 2%;
}
.ml-3 {
  margin-left: 3%;
}
.ml-4 {
  margin-left: 4%;
}

.mr-2 {
  margin-right: 2%;
}

.mt-2 {
  margin-top: 2%;
}
.mt-4 {
  margin-top: 4%;
}
.mt-8 {
  margin-top: 8%;
}
.mt-20 {
  margin-top: 20%;
}

.mb-2 {
  margin-bottom: 2%;
}
.ma-2 {
  margin: 2%;
}
.div-relative {
  position: relative;
  /* width: fit-content; */
}

input:focus,
select:focus,
textarea:focus {
  outline: 1.5px solid #c1c1c1;
  background-color: white;
}

input[type="radio"]:focus {
  outline: 0px;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}

.select-container {
  /* max-width: 156px; */
  min-width: 156px;
}

.canvas-container {
  max-width: 400px;
  margin-top: 20px;
}

.nav {
  height: 40px;
  background-color: var(--color-accent);
  position: fixed;
  display: flex;
  border: none;
  top: 0;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.nav-ul {
  list-style-type: none;
  color: white;
  padding-top: 10px;
  margin: 0;
  display: flex;
}

.nav-ul li {
  padding-left: 10px;
  padding-right: 74px;
  font-family: var(--fuenteMedium);
  font: 17px;
}

/* reset password */

.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.reset-form-container {
  width: 350px;
  margin: auto;
}

.reset-spanInfo {
  color: rgb(150, 148, 148);
  font-size: 14px;
  font-family: var(--fuenteLight);
  font-weight: lighter;
  margin: 2px 5px;
}

.reset-container #btnForm {
  margin-top: 16px;
  padding-top: 4px;
  background: var(--color-primary);
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: white;
  width: 230px;
  height: 30px;
  border-radius: 10px;
  border: 0ch;
  cursor: pointer;
}

.reset-card {
  /* background: red; */
  text-align: center;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  max-width: 400px;
  height: 450px;
  border-radius: 20px;
  box-shadow: -2px 1px 38px -4px rgba(74, 74, 74, 0.25);
  -webkit-box-shadow: -2px 1px 38px -4px rgba(74, 74, 74, 0.25);
  -moz-box-shadow: -2px 1px 38px -4px rgba(74, 74, 74, 0.25);
}

.reset-image {
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.message {
  font-family: var(--fuenteLight);
}

.nav-ul li a {
  color: white;
  text-decoration: none;
}

.header-admin-bar-items {
  gap: 10px;
  display: flex;
  align-items: center;
}
.header-admin-bar-items-long {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-admin-bar-items-long > div {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.form-control-check.flex {
  display: flex;
  margin-right: 2rem;
}

.form-control-check.flex label {
  position: relative;
  top: 6px;
}

.form-control-check {
  display: block;
  min-height: 1.5rem;
  /* padding-left: 1.5em; */
  margin-bottom: 0.125rem;
}

.form-control-check.inline {
  display: inline;
  margin-left: 6px;
}

#segundo {
  font-size: 18px;
}

.form-control {
  display: block;
  padding: 6px 12px;
  background: #fcfafa;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  height: initial;
  width: 100%;
  box-sizing: border-box;
  color: #000;
}

.flex-check {
  display: flex;
  margin-bottom: 4px;
}

.flex-check input {
  display: inline;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-group .material-icons.important {
  color: var(--color-accent);
  font-size: 15px;
  position: relative;
  top: 2px;
  margin-left: 5px;
}

.inline-check {
  display: flex;
  align-items: center;
}

.inline-check label {
  font-family: var(--fuenteLight);
}

.data-empty span {
  font-family: var(--fuenteMedium);
}

abbr[title] {
  text-decoration: none;
}

.button-control {
  display: block;
  margin-left: auto;
}

.button-control div {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.button-control button {
  background-color: var(--color-primary);
  border: none;
  font-family: var(--fuenteBold);
  font-size: 14px;
  color: white;
  border-radius: 10px;
  padding: 5px 15px;
  margin-left: 4px;
}

.params-container ul {
  padding: 0px;
}

.params-container ul .form-control {
  max-width: 400px;
}

.params-form-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.params-container .form-control {
  min-width: 200px;
  max-width: 200px;
}

.button-control img {
  height: 100%;
  max-height: 250px;
  max-width: 200px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  /* padding: 10px; */
}

.grid-container.col-3 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-container.col-4 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-container.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-container.col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-container.col-2.container {
  grid-template-columns: 75% 25%;
}

.grid-container.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-item {
  padding: 0px 12px;
}

.grid-item-col-2 {
  display: grid;
  grid-template-columns: 90% 10%;
}

.form-group label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

.check-container.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-modal-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.spanVerify {
  display: block;
  height: 1.5px;
  position: relative;
  background-color: var(--color-bg);
  bottom: 8px;
  left: 6px;
  width: 135px;
  overflow: hidden;
}

.labelLoading {
  height: 1.5px;
  background-color: var(--color-primary);
  transition: left 0.5s ease-in-out;
  width: 30px;
}

.icon-input {
  position: relative;
  top: 20px;
}

.sidebar-ul {
  display: none;
}

.flex-gap-2 {
  gap: 4px;
}

.flex-gap-20 {
  gap: 20px;
}

.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}

.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.border-bottom {
  border-bottom: 1px solid #d7d7d778;
  margin-bottom: 10px;
}

.main-body {
  width: 100%;
  height: 100vh;
}

.main-body-action {
  display: flex;
  justify-content: end;
}

.main-admin-bar {
  display: flex;
  gap: 1vw;
  margin-bottom: 30px;
  color: var(--color-accent);
  box-sizing: border-box;
  background: var(--admHeaderSidebar);
  padding: 14px 20px;
  align-items: center;
  border-radius: 10px;
  /* border: 1px solid #c0bebe; */
  min-width: 90%;
  max-width: 90%;
  flex-wrap: wrap;
}

.main-admin-bar select,
.main-admin-bar input[type="date"] {
  width: 170px;
  border-radius: 7px;
  height: 25px;
  border: 1px solid var(--color-bg-variant);
  padding-left: 7px;
  font-size: 14px;
  font-family: var(--fuenteMedium);
  color: #9e9e9e;
  background-color: white;
}

.relative-class {
  position: relative;
}

.select {
  width: 170px;
  border-radius: 7px;
  height: 25px;
  border: 1px solid var(--color-bg-variant);
  padding-left: 7px;
  font-size: 14px;
  font-family: var(--fuenteMedium);
  color: #9e9e9e;
  background-color: white;
}

.main-admin-bar .centro-search {
  width: 170px;
  border-radius: 7px;
  height: 25px;
  border: 1px solid var(--color-bg-variant);
  padding-left: 7px;
  font-size: 14px;
  font-family: var(--fuenteMedium);
  color: #9e9e9e;
  background-color: white;
}

.main-admin-bar select option {
  font-family: var(--fuenteLight);
}

.main-admin-bar .main-admin-button {
  height: 28px;
  width: 28px;
  border-radius: 7px;
  background-color: var(--color-primary);
  font-family: var(--fuenteBold);
  color: white;
  border: none;
  padding-top: 0;
  font-size: 1.5rem;
}

.subtitle {
  font-family: var(--fuenteBold);
  font-size: 1.22rem;
  color: var(--color-accent);
  font-weight: bold;
  margin: 0px;
}
.sub-info {
  font-family: var(--fuenteBold);
  font-size: 1rem;
  color: var(--color-primary);
  font-weight: bold;
}
.main-admin-bar h2 {
  font-family: var(--fuenteBold);
  font-size: 1.3rem;
  color: var(--color-primary);
  margin: 0px;
}

.main-admin-bar .select-filter-section {
  display: flex;
  flex-direction: column;
}

.table-container {
  max-height: 70vh;
  min-height: 50vh;
  overflow-y: scroll;
  width: 90%;
  max-width: 70vw;
  background-color: white;
  border: 1px solid #c0bebe;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: rgb(254, 254, 254);
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar {
  width: 10px;
}

.table-adm {
  width: 100%;
  border-collapse: unset;
}
.campo-obligatorio {
  color: #ba0000;
  margin-left: 2px;
  cursor: default;
}
.table-adm thead {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px,
    rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

.table-adm thead tr td,
.table-adm th {
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: var(--color-accent);
  font-weight: 600;
}

.table-adm tbody {
  font-family: var(--fuenteMedium);
  font-size: 14px;
  color: var(--color-accent);
}

.btn-close-fullmodal {
  position: absolute;
  right: 4px;
  top: 9px;
  padding: 10px;
  border-radius: 70px;
  border: none;
  background: #323639;
  color: #ffffff;
  z-index: 1000;
}

.table-adm td,
.table-adm th {
  padding: 16px;
  text-align: center;
}

.imagen-cuadrada {
  object-fit: cover;
}

.align-spinner {
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.left-align {
  display: flex;
  align-items: flex-start;
}

.loading-background {
  position: absolute;
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.fullpage {
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #bbbbbb;
  border-top: 10px solid #035cc9;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@media (max-width: 575.98px) {
  .flex-gap-2 {
    gap: 5px;
    flex-wrap: wrap;
  }

  .nav {
    display: none;
    z-index: 2;
    box-shadow: none !important;
    transition: 0.1s ease all;
  }

  .nav-ul li,
  .nav-ul {
    font-size: 11.5px;
    padding: 3px 5px 3px 5px;
    width: auto;
  }

  .nav-ul {
    color: var(--color-primary);
    display: block;
  }

  .nav-ul li a {
    color: var(--color-primary);
    text-decoration: none;
  }

  .bottom {
    background-color: white;
    bottom: 15%;
    top: auto;
    height: 50px;
    justify-content: center;
    display: block;
  }

  .bottom ul {
    color: var(--color-primary);
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }

  .nav-ul li a {
    color: inherit;
    text-decoration: none;
  }

  .grid-container.col-sm-12 {
    grid-template-columns: 1fr;
  }

  .grid-container.col-2.container {
    grid-template-columns: 1fr;
  }

  .form-group label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: 12px;
  }

  .grid-container.col-sm-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .sidebar-ul {
    display: block;
  }

  .main-admin-bar {
    width: 100%;
    display: block;
  }

  .main-admin-bar h2 {
    font-size: 1rem;
  }

  .main-admin {
    padding: 20px;
  }

  .main-admin-bar select {
    width: 100%;
    height: 35px;
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .header-admin-bar-items,
  .header-admin-bar-items-long {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .main-admin-bar select {
    width: 100%;
    height: 35px;
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .header-admin-bar-items,
  .header-admin-bar-items-long {
    width: 100%;
  }

  .nav {
    z-index: 2;
    display: none;
    box-shadow: none !important;
  }

  .nav-ul {
    color: var(--color-primary);
    display: block;
  }

  .nav-ul li a {
    color: var(--color-primary);
    text-decoration: none;
  }

  .nav-ul li,
  .nav-ul {
    font-size: 19px;
    padding: 2px 15px;
    object-fit: contain;
  }

  .bottom {
    background-color: white;
    bottom: 15%;
    top: auto;
    height: 50px;
    justify-content: center;
    z-index: 2;
    display: block;
  }

  .nav-ul li a {
    color: inherit;
    text-decoration: none;
  }

  .bottom ul {
    color: var(--color-primary);
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }

  .bottom ul li {
    font-size: 14.5px;
  }

  .grid-container.col-md-4 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-container.col-lg-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container.col-md-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container.col-md {
    grid-template-columns: 1fr;
  }

  .grid-container.col-2.container {
    grid-template-columns: 75% 25%;
  }

  .grid-container.col-md.container {
    grid-template-columns: 1fr;
  }

  .form-group label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: 12px;
  }

  .sidebar-ul {
    display: block;
  }

  .main-admin-bar {
    width: 90%;
  }

  .main-admin-bar h2 {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) and (max-width: 1265px) {
  .grid-container.col-lg-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container.col-lg {
    grid-template-columns: 1fr !important;
  }

  .grid-container.col-lg-4 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-container.col-2.container {
    grid-template-columns: 75% 25%;
  }

  .form-group label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: 12px;
  }

  .bottom ul li {
    font-size: 14.5px;
  }

  .header-admin-bar-items,
  .header-admin-bar-items-long {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .nav {
    z-index: 2;
    display: none;
    box-shadow: none !important;
    opacity: 0;
  }

  .nav-ul {
    color: var(--color-primary);
    display: block;
  }

  .nav-ul li a {
    color: var(--color-primary);
    text-decoration: none;
  }

  .nav-ul li,
  .nav-ul {
    font-size: 19px;
    padding: 0 35px;
    object-fit: contain;
  }

  .bottom {
    background-color: white;
    bottom: 15%;
    top: auto;
    height: 50px;
    justify-content: center;
    z-index: 2;
    display: block;
    opacity: 1;
  }

  .nav-ul li a {
    color: inherit;
    text-decoration: none;
  }

  .bottom ul {
    color: var(--color-primary);
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }

  .bottom ul li {
    font-size: 14.5px;
  }

  .sidebar-ul {
    display: block;
  }

  .main-admin-bar select {
    width: 100%;
    height: 35px;
    font-size: 20px;
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .main-admin-bar input {
    font-size: 20px;
  }

  .header-admin-bar-items,
  .header-admin-bar-items-long {
    width: 100%;
  }
}

/* TOOLTIPS */

/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  --arrow-size: 5px;
  position: relative;
  z-index: 200;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + var(--arrow-size));
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 200px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(
    30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44)
  );
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size); /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + var(--arrow-size));
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + var(--arrow-size));
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(var(--arrow-size), 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(var(--arrow-size), 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + var(--arrow-size));
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, var(--arrow-size)) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, var(--arrow-size)) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px var(--arrow-size) var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

/* Pagination.jsx */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination span {
  margin: 0 10px;
}
