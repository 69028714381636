.optionMenu.unhidden,
.optionMenu.unhidden a,
.optionMenu.unhidden button {
  opacity: 1;
  position: relative;
  bottom: 0;
  visibility: visible;
  z-index: -1;
  pointer-events: all;
}
.optionReportes.unhidden,
.optionReportes.unhidden a,
.optionReportes.unhidden button {
  opacity: 1;
  position: relative;
  bottom: 0;
  visibility: visible;
  z-index: -1;
  pointer-events: all;
}

.optionReportes.unhidden {
  overflow: auto;
  min-height: 65px;
  height: 100%;
}

.optionCampos.unhidden,
.optionCampos.unhidden a,
.optionCampos.unhidden button {
  opacity: 1;
  position: relative;
  bottom: 0;
  visibility: visible;
  z-index: -1;
  pointer-events: all;
  background-color: #f2f6f8;
  height: auto;
}

.optionCampos {
  visibility: hidden;
  opacity: 0;
  height: 0.5px;
}

.optionMenu::-webkit-scrollbar-track {
  background-color: rgb(239, 239, 239);
  border-radius: 10px;
}

.optionMenu::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.optionMenu::-webkit-scrollbar {
  width: 10px;
}

.sideBar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--admHeaderSidebar);
  width: 240px;
  max-height: 100%;
  min-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-sizing: border-box;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 3px;
}

.sideBar::-webkit-scrollbar-track {
  background-color: rgb(254, 254, 254);
  border-radius: 10px;
}

.sideBar::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.sideBar::-webkit-scrollbar {
  width: 7px;
}

.sideBar .image-container {
  text-align: center;
  height: 65px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.logo {
  width: 130px;
  height: 100%;
  margin: 0 auto;
}

.sideBar button,
.sideBar a {
  padding: 14px 25px 13px 40px;
  box-sizing: border-box;
  display: flex;
  border: none;
  font-size: 15px;
  background-color: white;
  color: var(--color-accent);
  font-family: var(--fuenteMedium);
  align-items: center;
  text-align: left;
}

.invisibleCerrarSesion {
  visibility: hidden;
  opacity: 0;
  height: 0.5px;
}

button.btnCampos {
  width: 100%;
  position: relative;
  padding-left: 30%;
}

.sideBar .material-icons {
  color: var(--color-accent);
  position: relative;
  margin-right: 10px;
}

.sideBar .material-icons.showOptions {
  position: absolute;
  right: 10px;
  top: 35%;
  transition: all ease 0.5s;
}

.btnConfig {
  border: none;
  background-color: white;
  font-family: var(--fuenteMedium);
  height: 60px;
  font-size: 15px;
  color: var(--color-accent);
  padding-right: 35px;
  position: relative;
}

.optionMenu a,
.optionCampos a,
.optionReportes a {
  font-family: var(--fuenteMedium);
  height: fit-content;
  width: 100%;
  background-color: white;
  font-size: 15px;
  color: var(--color-accent);
  opacity: 0;
  pointer-events: none;
  border: none;
  padding-left: 30%;
  transition: all 0.5s ease;
}

.optionCampos a,
.optionCampos button {
  padding-left: 36%;
}

.optionMenu {
  margin-top: -15px;
  align-content: center;
  width: 100%;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  position: relative;
  bottom: 100px;
  z-index: -2;
  transition: 0.6s all ease;
}
.optionReportes {
  margin-top: -15px;
  align-content: center;
  width: 100%;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  position: relative;
  bottom: 100px;
  z-index: -2;
  transition: 0.6s all ease;
}

.optionCampos {
  align-content: center;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  bottom: 100px;
  z-index: -2;
  transition: 0.6s all ease;
}

#showConfigIcon.rotate {
  transform: rotate(180deg);
}
#showCampoIcon.rotate {
  transform: rotateZ(180deg);
}
#showReportesIcon.rotate {
  transform: rotateZ(180deg);
}

/*movil*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .sideBar {
    position: fixed;
    width: 100vw;
    visibility: hidden;
    padding-top: 10vh;
    right: -700px;
    opacity: 0;
    transition: 0.5s ease all;
    overflow: auto;
  }

  button.btnCampos {
    padding-left: 0;
  }

  .sideBar .image-container {
    padding-bottom: 50px;
  }

  .sideBar .logo {
    width: 45vw;
    display: block;
    margin: 0 auto 50px auto;
    transition: 0.5s ease all;
  }

  #btn-cerrar-sesion.invisibleCerrarSesion {
    visibility: visible;
    opacity: 1;
    justify-content: center;
    display: flex;
    gap: 10px;
    height: 60px;
    vertical-align: middle;
  }

  .optionMenu,
  .optionCampos {
    margin-top: 0;
  }

  .optionCampos {
    height: 5px;
  }

  .optionCampos.unhidden {
    overflow: auto;
    height: 100%;
  }
  .optionReportes.unhidden {
    overflow: auto;
    height: 100%;
  }

  .optionMenu,
  .optionCampos,
  .optionReportes,
  .optionCampos a .optionMenu a {
    width: 100vw;
  }

  .optionMenu a,
  .optionCampos a,
  .optionReportes a {
    padding-left: 0;
  }

  .sideBar .material-icons {
    font-size: 25px;
  }

  .sideBarVisible {
    display: block;
    right: 0;
    opacity: 1;
    z-index: 6;
    visibility: visible;
  }

  .sideBar button,
  .sideBar a {
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80px;
    font-size: 18px;
    text-align: center;
    margin: 0;
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) {
  .sideBar {
    position: fixed;
    width: 100vw;
    z-index: -3;
    visibility: hidden;
    opacity: 0;
    right: -1700px;
    transition: 0.5s ease all;
    overflow: auto;
  }

  #btn-cerrar-sesion.invisibleCerrarSesion {
    visibility: visible;
    opacity: 1;
    justify-content: center;
    display: flex;
    height: 60px;
    gap: 10px;
    vertical-align: middle;
  }

  button.btnCampos {
    padding-left: 5%;
  }

  .sideBar .image-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sideBarVisible {
    display: block;
    right: 0;
    opacity: 1;
    z-index: 6;
    visibility: visible;
  }

  .sideBar .logo {
    width: 25vw;
    height: auto;
    display: block;
    margin: auto auto 100px auto;
  }

  .optionMenu,
  .optionMenu a,
  .optionCampos,
  .optionCampos a {
    width: 100vw;
  }

  .optionCampos {
    height: 5px;
  }

  .optionCampos.unhidden {
    overflow: auto;
    height: 100%;
  }

  .sideBar button,
  .sideBar a {
    width: 100vw;
    height: 110px;
    font-size: 25px;
    text-align: center;
    display: block;
    margin: 0;
  }

  .optionMenu a,
  .optionCampos a {
    padding-left: 0;
  }

  .sideBar .material-icons {
    font-size: 35px;
  }

  .sideBar a {
    padding-top: 40px;
  }
}
