body {
  background: var(--color-bg);
  min-height: 100%;
}

.rootInicio {
  background: var(--color-bg);
}

.rootInicio h1 {
  font-family: var(--fuenteBold);
  font-size: 20px;
  height: fit-content;
  color: var(--color-primary);
}

.rootInicio h2 {
  font-family: var(--fuenteBold);
  font-size: 18px;
  color: var(--color-primary);
  margin-bottom: 10px;
  margin-top: -20px;
}

.rootInicio hr {
  margin-top: -35px;
  margin-bottom: 20px;
  width: 70%;
  border-color: var(--color-secondary);
}

.rootInicio .table-inicio {
  width: inherit;
  font-family: var(--fuenteLight);
  font-size: 14px;
  padding: 20px;
}

.rootInicio .table-inicio thead {
  position: sticky;
  top: 0;
  background-color: white;
}

.rootInicio .table-inicio thead tr td {
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: var(--color-accent);
  border-bottom: solid 1px #cfd8dc;
}

.rootInicio .table-inicio td {
  text-align: center;
  color: var(--color-primary);
  padding: 10px;
  white-space: wrap;
}

/* 
.rootInicio .table-inicio tbody tr {
  height: 65px; 
  text-align: center; 
  background-color: red;
} */

.rootInicio .table-inicio tr a {
  color: var(--color-primary);
}

.rootInicio .table-inicio .material-icons {
  color: var(--color-primary);
}

.rootInicio .fa-whatsapp {
  color: #40c351;
  font-size: medium;
  font-style: normal;
}

.rootInicio .principal-container {
  width: calc(100% - 295px);
  margin: 0 20px;
}

.rootInicio .menuPerfil {
  background: white;
  position: fixed;
  width: 255px;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.rootInicio .nav-inf {
  padding: 35px 30px 25px 0;
  background: var(--color-bg);
  height: 70px;
  display: flex;
  position: fixed;
  top: 40px;
  left: 0;
  right: 255px;
  z-index: 1;
}

.nav-inf img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.imageContainer {
  display: flex;
  padding-left: 70px;
  padding-right: 30px;
  height: 75px;
}

.rootInicio .nav-inf > img {
  height: 100%;
  width: 100%;
  margin-right: 1vw;
  object-fit: contain;
}

.rootInicio .homeLink {
  align-self: center;
  margin-top: 2px;
}

.rootInicio .title {
  background-color: white;
  padding-left: 30px;
  padding-top: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 5px;
  height: 70px;
  margin-left: 0px;
}

.title-image {
  margin-left: auto;
  margin-right: 14px;
  position: relative;
  top: 4px;
  height: 100%;
}

.title i {
  color: var(--color-primary);
  font-size: 18px;
}

.rootInicio .nav-inf .title img {
  margin-left: auto;
}

.rootInicio .container-inicio {
  width: fit-content;
  height: fit-content;
  padding-bottom: 70px;
  position: relative;
}

.container-inicio h2 {
  font-size: 1.3rem;
}

.rootInicio .tableArea {
  border-radius: 10px;
  background-color: white;
  width: 50vw;
  border: solid 1px #cfd8dc;
  height: 450px;
  overflow: auto;
  margin-top: 20px;
}

.tableArea::-webkit-scrollbar-track {
  background-color: var(--color-bg);
  border-radius: 10px;
}

.tableArea::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-variant);
  border-radius: 10px;
}

.tableArea::-webkit-scrollbar {
  width: 7px;
  height: 60%;
}

.rootInicio .tableArea h1 {
  font-family: var(--fuenteBlack);
  font-size: 25px;
  margin-left: 0;
  text-align: left;
  color: #9e9e9e;
  white-space: normal;
  margin-top: auto;
  margin-bottom: auto;
}

.rootInicio .tableArea img {
  height: 285px;
}

.rootInicio .mainColumn {
  text-align: left;
  font-family: var(--fuenteBold);
  color: var(--color-accent);
}

.rootInicio .colFontBlue {
  color: var(--color-accent);
}

.statusGreen {
  color: #1a7b43 !important;
  text-align: center;
}

.statusYellow {
  color: #bb9c27 !important;
  text-align: center;
}

.normalStatus {
  color: var(--color-accent) !important;
  text-align: center;
}

.statusRed {
  color: #a72c2c !important;
  text-align: center;
}

.rootInicio .menu-item {
  position: -webkit-sticky;
  position: sticky;
  top: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  padding: 25px;
  width: 20vw;
  margin-bottom: 25px;
  height: 450px;
  box-sizing: border-box;
  margin-top: 25px;
}

.rootInicio .menu-item .material-icons {
  color: var(--color-primary);
  font-size: medium;
  position: relative;
  top: 3px;
  left: 3px;
}

.rootInicio .menu-item button {
  background: var(--color-primary);
  padding: 8px 14px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "circularBold";
  color: white;
  position: relative;
  margin: 30px 0;
  border: none;
  box-shadow: 2px 1px 8px -2px grey;
}

.rootInicio .menu-item h2 {
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: var(--color-accent);
  margin-top: 0px;
}

.rootInicio .menu-item h3 {
  font-family: var(--fuenteBold);
  font-size: 18px;
  color: var(--color-accent);
  margin: 0px;
}

.rootInicio .menu-item h4 {
  font-family: var(--fuenteBlack);
  font-size: 14px;
  color: var(--color-accent);
  margin-top: 7px;
  margin-bottom: 4px;
}

.rootInicio .menu-item p {
  font-family: var(--fuenteLight);
  font-size: 14px;
  color: var(--color-accent);
  margin: 1px;
}

.rootInicio .menuPerfil label {
  font-family: var(--fuenteLight);
  font-size: 15px;
  color: var(--color-primary);
  margin-top: 5px;
  display: block;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.rootInicio .menuPerfil {
  background: white;
  position: fixed;
  width: 255px;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.rootInicio .menuPerfil > * {
  margin-top: 50px;
}

.rootInicio .menuPerfil label i {
  color: var(--color-secondary);
  margin-right: 15px;
  position: relative;
  top: 5px;
}

.rootInicio .menuPerfil i {
  color: var(--color-primary);
  margin: 5px 5px;
}

.btnNotificacion {
  background: transparent;
  border: none;
}

.rootInicio .menuPerfil h2 {
  color: var(--color-accent);
  margin-top: 20px;
}

.rootInicio .profileInfo {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 50px;
}

.rootInicio .profileInfo div {
  display: block;
  margin-top: 0px;
  padding-top: 6px;
  padding: 6px 12px 0px;
}

.rootInicio .profileInfo div i {
  color: var(--color-secondary);
}

.rootInicio .menu-item #nuevaSolicitud {
  width: 210px;
  max-height: 340px;
  position: absolute;
    bottom: 7px;
    right: 7px;
}

.rootInicio .toggleBtn i {
  font-size: 30px;
  color: var(--color-primary);
}

.rootInicio .toggleBtn {
  display: none;
  padding: 20px 0;
  border: none;
  background: transparent;
  position: fixed;
  right: 15px;
  top: 0;
}

.rootInicio .imgMovil {
  display: none;
}

.rootInicio .toggleBlue i {
  color: var(--color-primary);
}

.rootInicio #btnEdit {
  background: var(--color-primary);
  color: white;
  font-family: var(--fuenteMedium);
  font-size: 16px;
  border-radius: 10px;
  padding: 5px 20px;
  position: relative;
  margin: 35px;
  border: none;
  box-shadow: 2px 1px 8px -2px grey;
}

.rootInicio #solicitudes {
  margin-bottom: 70px;
}

.rootInicio #profileImg {
  border-radius: 100px;
  width: 130px;
  height: 130px;
}

.rootInicio #itemAyuda {
  height: 160px;
}

.rootInicio #logoCenfo {
  width: 50px;
  position: absolute;
  right: 0;
  bottom: 44px;
}

.popout {
  position: fixed;
  bottom: 3rem;
  right: 8px;
  display: none;
}

.popout .btn {
  display: inline-block;
  position: relative;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 32px;
  z-index: 999;
  background: #0e6aea;
  color: white;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
  transition: 0.2s opacity ease-in-out;
  -webkit-transition: 0.2s opacity ease-in-out;
}

/*movil*/

@media (max-width: 575.98px) {
  .principal-container {
    width: 100%;
  }
  body {
    text-align: left;
    min-height: 100vh;
    width: 100%;
  }

  .imageContainer {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    width: 0;
  }

  .popout {
    display: block;
  }

  .rootInicio h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: auto;
    margin-top: auto;
  }

  .rootInicio .table-inicio {
    zoom: 73%;
  }

  .rootInicio .toggleBtn {
    display: flex;
    z-index: 2;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.5s ease all;
  }

  .toggleBtn.invisibleBtn {
    opacity: 0;
    transform: rotate(180deg);
    transition: 0.5s ease all;
  }

  .rootInicio .imgMovil {
    display: flex;
    padding: 6px 0px;
  }

  .rootInicio .imgEsc {
    display: none;
  }

  .rootInicio .menuPerfil {
    width: 100%;
    z-index: 1;
    padding-top: 1%;
    position: fixed;
    right: -400px;
    opacity: 0;
    transition: 0.5s ease all;
  }

  .rootInicio .Active {
    right: 0;
    opacity: 1;
  }

  .rootInicio .menuPerfil label {
    text-align: center;
    margin-left: -45px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .rootInicio .nav-inf {
    height: 60px;
    padding: 0;
    width: 100vw;
    top: 0;
  }

  .rootInicio .nav-inf img {
    width: 2.5rem;
    position: absolute;
    left: 0;
    right: 500px;
    top: 0;
    z-index: 1;
    margin-left: 1.2rem;
  }

  .rootInicio .title {
    object-fit: contain;
    position: relative;
    margin: 0;
    padding: 10px 44px 10px 65px;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .rootInicio .title img {
    display: none;
  }

  .rootInicio .principal {
    width: fit-content;
    position: relative;
    top: 120px;
    display: block;
    margin: auto;
  }

  .rootInicio .container-inicio {
    height: fit-content;
    width: fit-content;
    padding: 0;
    padding-bottom: 40px;
  }

  .rootInicio .menu-item img {
    padding-left: 4px;
  }

  .rootInicio .tableArea {
    width: 330px;
    display: block;
    height: fit-content;
    max-height: 450px;
  }

  .rootInicio .tableArea h1 {
    padding: 0 50px;
    font-size: 20px;
    text-align: center;
  }

  .rootInicio .tableArea img {
    height: 111px;
    display: block;
    margin: auto;
  }

  .rootInicio .menu {
    position: relative;
    top: 200px;
    left: 0px;
    bottom: 0;
    width: fit-content;
    height: fit-content;
    margin: 0px auto;
    display: flex;
    gap: 30px;
    zoom: 59%;
    text-align: left;
  }

  .rootInicio .menu-item {
    width: 115vw;
    height: fit-content !important;
  }

  .rootInicio .menu-item h2 {
    font-size: 26px;
  }

  .rootInicio .menu-item h4 {
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .rootInicio #logoCenfo {
    width: 65px;
    bottom: 44px;
  }

  .rootInicio .menu-item p {
    font-size: 18px;
    margin: 4px;
  }

  .addBtn {
    display: none !important;
  }

  .rootInicio .profileInfo div {
    padding: 6px 60px 0px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 1022px) {
  .principal-container {
    width: 100%;
  }
  .rootInicio {
    text-align: left;
    min-height: 100vh;
    width: 100%;
  }

  .imageContainer {
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }

  .rootInicio .profileInfo div {
    padding: 6px 60px 0px;
  }

  .addBtn {
    display: none !important;
  }

  .popout {
    display: block;
  }

  .rootInicio h1 {
    text-align: center;
    font-size: 20px;
    margin-bottom: auto;
    margin-top: auto;
  }

  .rootInicio .homeLink {
    margin-top: 4px;
  }

  .rootInicio .table-inicio {
    zoom: 90%;
  }

  .rootInicio .toggleBtn {
    display: flex;
    z-index: 2;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.5s ease all;
  }

  .toggleBtn.invisibleBtn {
    opacity: 0;
    transform: rotate(180deg);
    transition: 0.5s ease all;
  }

  .rootInicio .imgMovil {
    display: flex;
  }

  .rootInicio .imgEsc {
    display: none;
  }

  .rootInicio .menuPerfil {
    width: 28%;
    zoom: 70%;
    z-index: 1;
    padding-top: 1%;
    right: -400px;
    opacity: 0;
    transition: 0.5s ease all;
  }

  .rootInicio .Active {
    right: 0;
    opacity: 1;
  }

  .rootInicio .menuPerfil label {
    text-align: center;
    margin-left: -45px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .rootInicio .nav-inf {
    height: fit-content;
    padding: 0;
    width: 100vw;
    top: 0;
  }

  .rootInicio .nav-inf img {
    width: 2.5rem;
    position: absolute;
    left: 0;
    right: 500px;
    top: 0;
    z-index: 1;
    margin-left: 1.2rem;
  }

  .rootInicio .title {
    object-fit: contain;
    position: relative;
    margin: 0;
    padding: 10px 44px 10px 90px;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .rootInicio .title img {
    display: none;
  }

  .rootInicio .principal {
    width: fit-content;
    position: relative;
    top: 160px;
    display: block;
    margin: auto;
  }

  .rootInicio .container-inicio {
    height: fit-content;
    width: fit-content;
    padding: 0;
    margin: 0;
    padding-bottom: 40px;
  }

  .rootInicio .tableArea {
    width: 80vw;
    display: block;
    height: fit-content;
    max-height: 450px;
  }

  .rootInicio .tableArea h1 {
    padding: 0 50px;
    font-size: 20px;
    text-align: center;
  }

  .rootInicio .tableArea img {
    height: 111px;
    display: block;
    margin: auto;
  }

  /* aqui */
  .rootInicio .menu {
    position: relative;
    top: 100px;
    left: 0px;
    bottom: 0;
    width: 70vw;
    height: fit-content;
    margin: 50px auto;
    display: flex;
    gap: 30px;
    text-align: left;
  }

  .rootInicio .menu-item {
    width: inherit;
    height: fit-content !important;
  }

  .rootInicio .menu-item h2 {
    font-size: 22px;
  }

  .rootInicio .menu-item h4 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .rootInicio #logoCenfo {
    width: 60px;
    bottom: 44px;
  }

  .rootInicio .menu-item p {
    font-size: 14px;
    margin: 4px;
  }

  /* hasta aqui */
}

/*fin movil*/

@media (min-width: 992px) and (max-width: 1330px) {
  .principal-container {
    width: calc(100% - 295px);
    margin: 0 20px;
  }
  .rootInicio .menu {
    right: 255px;
    margin-right: 1.5vw;
  }

  .rootInicio .menu-item {
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    width: 12vw;
    margin-bottom: 25px;
  }

  .rootInicio .menu-item h2 {
    font-size: 14px;
  }

  .rootInicio .menu-item h3 {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .rootInicio #logoCenfo {
    width: 28px;
    bottom: 50px;
  }

  .rootInicio .menu-item h4 {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .rootInicio .menu-item #nuevaSolicitud {
    width: 80%;
  }

  .rootInicio .menu-item button {
    padding: 5px 8px;
    font-family: "circularBold";
    bottom: 0px;
    left: 0px;
  }

  .rootInicio #itemAyuda {
    height: auto;
  }

  .rootInicio .menu-item p {
    font-size: 12px;
    margin: 2px;
  }
}
