header {
  display: flex;
  position: fixed;
  height: 70px;
  background-color: var(--admHeaderSidebar);
  right: 0;
  left: 240px;
  top: 0;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  z-index: 6;
}

header > .image-container {
  width: auto;
  height: inherit;
}

header h1 {
  align-self: flex-end;
  font-family: var(--fuenteBold);
  color: var(--color-primary);
  margin-left: 7px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
  font-size: 24px;
}

.userTitle {
  display: flex;
  gap: 18px;
  margin-right: 20px;
  font-family: var(--fuenteLight);
  font-size: 14px;
  color: var(--color-accent);
  background: #fafafadb;
  padding: 0 10px;
  border-radius: 13px;
  align-items: center;
  height: 45px;
}

.userTitle button {
  border: none;
  color: var(--color-accent);
  height: fit-content;
  padding: 5px 0px 5px 12px;
  border-radius: 10px;
}

.userTitle i {
  position: relative;
  right: 7px;
}

.toggleBtnAdm {
  height: 40px;
  width: 40px;
  display: none;
  padding: 5px;
  border: none;
  position: fixed;
  background: transparent;
  margin-top: 17px;
  right: 5px;
}

.toggleBtnAdm .material-icons {
  color: var(--color-primary);
  font-size: 25px;
}

.headerImg {
  position: relative;
  left: -4px;
  width: inherit;
  height: inherit;
}

/*movil*/
@media (max-width: 575.98px) and (orientation: portrait) {
  header {
    margin: 0;
    width: 100vw;
    height: 70px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
  }

  header h1 {
    font-size: 15px;
    white-space: normal;
  }

  .toggleBtnAdm.invisibleBtn {
    opacity: 0;
    transform: rotate(180deg);
    transition: 0.5s ease all;
  }

  .toggleBtnAdm {
    display: flex;
    margin-left: 20px;
    z-index: 6;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.5s ease all;
    top: 0px;
    margin-top: 20px;
  }

  .userTitle {
    display: none;
  }

  header .titleSolicitudes {
    top: 0px;
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) {
  header {
    margin: 0;
    width: 100vw;
    height: 70px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
  }

  header h1 {
    font-size: 30px;
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
  }

  .toggleBtnAdm {
    display: flex;
    margin-top: 20px;
    right: 23px;
    z-index: 6;
    top: 0;
    transform: rotate(0deg);
    transition: 0.5s ease all;
  }

  .toggleBtnAdm.invisibleBtn {
    opacity: 0;
    transform: rotate(180deg);
    transition: 0.5s ease all;
  }

  .toggleBtnAdm .material-icons {
    font-size: 33px;
  }

  .userTitle {
    display: none;
  }
}
