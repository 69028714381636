.notifContainer {
  width: 22vw;
  min-width: 305px;
  height: fit-content;
  background: white;
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 15px 30px;
  transition: 0.5s ease all;
  transform: scale(0.5);
  opacity: 0;
  position: absolute;
  right: 174px;
  z-index: 5;
  max-height: 405px;
  overflow-y: scroll;
  visibility: hidden;
  right: 190px;
}

.notifContainer::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}

.notifContainer::-webkit-scrollbar-thumb {
  background-color: var(--color-bg);
  border-radius: 10px;
}

.notifContainer::-webkit-scrollbar {
  width: 7px;
  height: 60%;
}

.active.notifContainer {
  transform: scale(1);
  opacity: 1;
  display: block;
  visibility: visible;
}


.notifInfo {
  overflow: hidden;
  display: grid;
  place-items: left;
  margin: 0;
  padding: 0;
}

.notifContainer p {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  margin: 0;
}

.notifContainer div {
  margin-bottom: 15px;
}

.menuPerfil .notifHeader {
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding-bottom: 0px;
  border-bottom: 1px solid #e8e8e8;
}

.menuPerfil .notifTitle {
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding-bottom: 0px;
  border-bottom: 1px solid #e8e8e8;
  margin-top: -15px !important;
  font-family: var(--fuenteBold);
  color: var(--color-accent);
  font-size: 16px;
}
.menuPerfil .notifBody {
  overflow-y: scroll;
  height: 200px;
}

.notifHeader h3 {
  font-weight: 500;
  font-size: 15px;
  color: rgb(140, 140, 140);
  text-align: left;
  margin: 0;
}

.notifHeader button {
  background: transparent;
  border: none;
}

.notifHeader button .material-icons {
  font-size: 15px;
}


.modalFlex {
  display: flex;
}

.btnCloseModal {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  border-radius: 5px;
}

.btnCloseModal .material-icons {
  color: gray;
}

.btnModalConfirm {
  border-radius: 7px;
  font-family: var(--fuenteMedium);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background: transparent;
  position: absolute;
  bottom: 25px;
  right: 40px;
}

.btnBack {
  background: transparent;
  border: none;
  margin: 0;
}

#detailTitle {
  display: flex;
  font-family: var(--fuenteMedium);
  font-weight: lighter;
  color: var(--color-accent);
  font-size: 14px;
  /* margin-bottom: -45px; */
  margin-top: 0;
}

@media (max-width: 575.98px) {
  .notifContainer {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
}


