
.rootVerify {
  background-color: white;
  height: 100vh;

}

.rootVerify .toggleBtn {
  display: none !important;
}

.rootVerify .contentVerify {
  padding: 100px 75px;
  background: white;
}

.inline-control {
  display: flex;
  height: 40px;
}

.verify-button {
  padding: 2px 16px;
  height: inherit;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteMedium);
  font-size: 16px;
  color: var(--color-accent);

}


.rootVerify .greenIcon {
  color: #90C418;
}

.rootVerify .redIcon {
  color: #FC4850;
}

.rootVerify .blueIcon {
  color: var(--color-primary);
  position: relative;
  top: 4px;
}

.item-title {
  display: flex;
}

.rootVerify h1 {
  font-family: var(--fuenteBold);
  font-size: 44px;
  color: var(--color-accent);
  margin-bottom: 30px;
  display: flex;
}

.rootVerify p {
  font-family: var(--fuenteLight);
  color: var(--color-accent);
  font-size: 22px;
  margin-bottom: 38px;
}

.rootVerify label {
  font-family: var(--fuenteMedium);
  font-size: 16px;
}



.link-text i {
  color: inherit;
  position: relative;
  top: 4px;
}

.link-text {
  display: block;
  width: fit-content;
  font-family: var(--fuenteLight);
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
}

.link-text:hover {
  color: #454545
}

.rootVerify .verify a {
  display: block;
  font-family: var(--fuenteLight);
  font-size: 16px;
  color: var(--color-primary);
}

.rootVerify .verify {
  display: flex;
  margin-bottom: 69px;
}


.rootVerify .span-message {
  color: #949798;
  font-family: var(--fuenteMedium);
  font-size: 16px;
  border-radius: 10px;
  background: var(--color-bg);
  padding: 15px 15px;
  display: block;
}

.span-message.success {
  color: var(--toastify-color-success);
  font-family: var(--fuenteLight);
}

.rootVerify #submit {
  width: 98px;
  height: 44px;
  margin-left: 0px;
  color: var(--color-accent);
  padding: 0;

}


.rootVerify hr {
  height: 1px;
  border: 0ch;
  margin-bottom: 23px;
  background-color: gainsboro;
}

.rootVerify .buttonVerify {
  border-radius: 10px;
  height: 34px;
  width: 130px;
  background-color: var(--color-primary);
  color: white;
  border-style: none;
  font-family: var(--fuenteBold);
  font-size: 16px;
  /* position: relative;
  bottom: 20px; */
}
.buttonOmitir{
  background-color: #808080d4 !important;
  margin-left: 4px;
}

.imgContainer .imgVerify {
  width: 85%;
  height: 85%;
}

.imgContainer {
  display: flex;
  justify-content: end;
}


.rootVerify .modalContainer select, .rootVerify .modalContainer input{
  height: inherit;
  width: 100%;
  display: block;
  margin-bottom: 0px;
}

/*movil*/

@media (max-width: 575.98px) {

  .rootVerify .contentVerify {
    padding: 60px 25px;
  }

  .rootVerify p {
    font-size: 18px;
  }

  .rootVerify .nav {
    display: flex;
    opacity: initial;
    justify-content: center;
  }

  .rootVerify .nav-ul {
    color: white;
    display: flex;
    align-items: center;
  }

  .rootVerify .nav-ul li {
    font-size: 13px;
  }

  .rootVerify .verify {
    width: 80%;
    flex-wrap: wrap;
    margin-bottom: 11%;
  }

  .rootVerify .buttonVerify {
    margin-left: 10px;
  }


  .rootVerify .span-message {
    padding: 8px;
    font-size: 13px;
  }

  .rootVerify h1 {
    text-align: center;
    font-size: 33px;
    margin-bottom: 0;
    margin-top: 10px;
    order: -1;
  }




  .rootVerify h2 {
    font-family: var(--fuenteLight);
    font-size: 26px;
  }

  .imgContainer {
    order: -1;
    justify-content: center;
    height: 200px;
  }
}


/*tablet */
@media (min-width: 576px) and (max-width: 991.98px) {


  .rootVerify .nav {
    display: flex;
    opacity: initial;
  }

  .rootVerify .nav-ul {
    color: white;
    display: flex;
    align-items: center;
  }

  .rootVerify h1 {
    font-size: 33px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .rootVerify h2 {
    font-size: 26px;
  }



  .rootVerify .span-message {
    padding-right: auto;
  }

  .imgContainer {
    order: -1;
    justify-content: center;
    height: 250px;
  }

}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) {}

/*fin tablet*/