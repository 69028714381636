.rootConfig {
  background: var(--color-bg);
  min-height: 100%;
  overflow: hidden;
}

.rootConfig h2 {
  font-family: var(--fuenteBold);
  font-size: 24px;
  color: var(--color-primary);
  margin-bottom: 10px;
  margin-top: -20px;
}

.form-config {
  padding-top: 10px;
}


.rootConfig .container-root h2 {
  margin: 0;
}

.rootConfig .container-root {
  height: auto;
  margin-bottom: 20px;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
}



/*movil''''''''''''''''''''''''''''''''''*/
@media (max-width: 575.98px) {

  .rootConfig .principal {
    display: block;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
    width: fit-content;
  }

  .rootConfig .container-root {
    width: 75vw;
    height: fit-content;
    padding: 20px 10px;
  }

  .rootConfig .container-root h2 {
    font-size: 17px;
    margin-left: 20px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) {


  .rootConfig .principal {
    display: block;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
    width: fit-content;
    top: 100px;
  }
}
