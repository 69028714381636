body {
  background: var(--color-bg);
  min-height: 100%;
}

body div {
  margin: 0px;
}

.loadingContainer.loadingHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 1s ease;
}

.loadingHidden .divLoader {
  visibility: hidden;
  opacity: 0;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divLoader {
  position: absolute;
}

.fix-width {
  width: fit-content;
}

th.up-icon {
  background-image: url("../../assets/up.png");
}
th.down-icon {
  background-image: url("../../assets/down.png");
}
th.default-icon {
  background-image: url("../../assets/default.png");
}
th.up-icon,
th.default-icon,
th.down-icon {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.info-text {
  font-family: var(--fuenteLight);
  font-size: 13px;
  white-space: wrap;
  line-height: 20px;
  color: #363636;
}

.icon-file-group {
  width: 20px;
  margin-right: 12px;
  font-size: 26px !important;
  opacity: 0.8;
}

.table-archivos {
  font-family: var(--fuenteBold);
  border-collapse: collapse;
  width: inherit;
  /* max-width:100%; */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.table-archivos tbody {
  background-color: rgb(252, 253, 255);
}

.table-archivos th,
td {
  padding: 20px;
  font-size: 14px;
  border: none;
}

.table-archivos td i {
  position: relative;
  font-size: 18px;
  top: 2px;
  color: #b91414;
}

.info-tooltip {
  color: rgb(23, 109, 239) !important;
  cursor: pointer;
}
.table-archivos th {
  background-color: #00489b;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}
h2 {
  font-family: var(--fuenteBold);
  font-size: 24px;
  color: var(--color-primary);
  margin-bottom: 10px;
  margin-top: -20px;
}

.file-content {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 7px 12px;
  position: relative;
}

.file-content span {
  font-family: var(--fuenteMedium);
  color: var(--color-primary);
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20vw;
  max-width: 90%;
}

.file-content i {
  position: absolute;
  color: var(--color-primary);
  font-size: 20px;
  right: 6px;
  cursor: pointer;
}

.container-form {
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  text-align: left;
}

.form-solicitud {
  display: block;
  padding: 50px;
  -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
}

.form-subheader {
  color: var(--color-primary);
  margin-left: 10px;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  font-size: 15px;
}

.principal-solicitud {
  margin-top: 170px;
  margin-left: 50px;
  margin-right: 290px;
}

.steps {
  border-radius: 10px;
  background: white;
  height: 54px;
  margin-bottom: 15px;
  display: flex;
  pointer-events: none;
  justify-content: center;
}

.steps > div {
  width: 14.28%;
}

.actualStep {
  background-color: #e6f1ff;
  overflow: hidden;
  object-fit: contain;
}

.actualStep .stepDesc {
  display: block;
}

.stepDesc {
  font-size: 11px;
  display: none;
}

.actualStep i {
  color: var(--color-primary) !important;
}

.steps div {
  padding: 10px 1vw 0 20px;
  overflow: hidden;
  font-family: var(--fuenteLight);
  white-space: nowrap;
  font-size: 14px;
  color: var(--color-accent);
}

.steps .isActive {
  pointer-events: all;
  cursor: pointer;
}

.steps div > div {
  display: block;
  position: relative;
  top: -30px;
  left: 10px;
}

.actualStep div {
  margin-top: -5px;
}

.steps i {
  color: var(--color-secondary);
  margin: 5px;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.steps label {
  cursor: pointer;
}

.profileInfo {
  margin-left: 40px;
  margin-right: 20px;
}

.toggleBlue i {
  color: var(--color-primary);
}

.info {
  font-family: var(--fuenteLight);
  font-size: 10px;
  position: relative;
  color: #c1c1c1;
  font-weight: lighter;
  margin-top: 4px;
  display: block;
}

#inputEspecFinance {
  margin-top: 10px;
  width: 200px;
}

#inputEspecHobby {
  background: white;
  border-radius: 10px;
  padding-left: 15px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  width: 200px;
  height: 25px;
  position: relative;
  bottom: 30px;
  left: 60px;
}

#inputOtro {
  background: hsl(0, 25%, 98%);
  border-radius: 10px;
  padding-left: 15px;
  width: 140px;
  height: 25px;
  position: absolute;
  display: initial;
  margin-left: 4px;
}

.fileInput {
  position: absolute;
  cursor: pointer;
  display: none !important;
}

.fileInput + .label-ins {
  font-size: 14px;
  font-family: var(--fuenteLight);
  color: gray;
  background-color: #fcfafa;
  display: block;
  /* width: 150px;
          height: 20px; */
  border: solid 1px #c1c1c1;
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

#inputPhoto-p {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
}

#inputLabel {
  font-size: 16px;
  font-family: var(--fuenteBold);
  color: gray;
  background-color: #fcfafa;
  display: block;
  height: 30px;
  border: solid 1px #c1c1c1;
  border-radius: 10px;
  text-align: right;
  padding: 0 15px;
}

.subLabel {
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

.labelTitle {
  margin-top: 20px;
  color: var(--color-primary);
  font-family: var(--fuenteBold);
  font-size: 15px;
}

.title-container {
  padding-bottom: 14px;
}

.form-title {
  font-family: var(--fuenteBold);
  font-size: 20px;
  color: var(--color-primary);
  font-weight: 600;
}

.sub-title {
  font-family: var(--fuenteLight);
  font-size: 14px;
  color: var(--color-accent);
}

textarea {
  display: block;
  padding-left: 15px;
  margin-bottom: 10px;
  background: #fcfafa;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  height: 50px;
}

#inputAlergia {
  display: inline;
  width: 200px;
  margin-left: 14px;
}

canvas {
  display: block;
  padding-left: 15px;
  margin-top: 3px;
  width: 165px;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  height: 50px;
}

.terms {
  margin: 20px;
  padding-right: 15px;
  max-height: 280px;
  overflow-y: auto;
  font-family: var(--fuenteLight);
  font-size: 13px;
  white-space: wrap;
  line-height: 20px;
}

.terms::-webkit-scrollbar-track {
  background-color: var(--color-bg);
}

.terms::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 7px;
}

.terms::-webkit-scrollbar {
  width: 7px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  border: none;
  color: #006aeb;
  background-color: transparent;
  padding: 4px 17px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
}

.upload-btn-wrapper .btn .material-icons {
  color: #00489b;
}

/*movil''''''''''''''''''''''''''''''''''*/
@media (max-width: 575.98px) {
  .form-c {
    margin-top: 12px;
  }
  .title-container {
    text-align: center;
  }

  .form-title,
  .sub-title {
    display: block;
  }

  .loader {
    position: static;
  }

  .profileInfo hr {
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .title h1 i {
    top: 3px;
    font-size: 17px;
  }

  .steps label {
    display: none;
  }

  .steps div {
    padding: 10px 24px 0 24px;
  }

  .principal-solicitud {
    display: block;
    margin: 100px auto;
    width: fit-content;
    box-sizing: border-box;
  }

  .steps {
    width: 370px;
    margin: 0px auto 14px;
  }

  .steps i {
    display: block;
    padding: 0;
    margin: -6px;
  }

  .steps div > div {
    top: -3px;
    left: -42px;
    overflow: visible;
  }

  .actualStep i {
    top: 5px;
  }

  .actualStep .stepDesc {
    display: block;
    margin: auto;
    font-size: 12px;
    margin-top: 3px;
  }

  .imgEsc {
    display: none;
  }

  .labelTitle {
    margin-top: 20px;
  }

  .labelTitleAca {
    color: var(--color-primary);
    margin-top: -10px;
  }

  .notActive {
    position: fixed;
    left: 0;
    display: none;
  }

  .title {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    padding: 20px 100px;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .title img {
    display: none;
  }

  .form-solicitud {
    padding: 15px;
  }

  .file-content span {
    width: 250px;
    max-width: 90%;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .form-c {
    margin-top: 12px;
  }
  .loader {
    position: static;
  }

  .labelTitleAca {
    margin: 50px 0 20px 0;
  }

  .profileInfo hr {
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .title h1 i {
    position: relative;
    top: 3px;
    font-size: 17px;
  }

  .steps label {
    display: none;
  }

  .steps div {
    width: 10vw;
    padding: 10px 24px 0 24px;
  }

  .principal-solicitud {
    display: block;
    margin: 100px 20px;
    width: fit-content;
    box-sizing: border-box;
  }

  .steps {
    width: 99vw;
  }

  .steps i {
    display: block;
    padding: 0;
    margin: -6px;
  }

  .steps div > div {
    top: -6px;
    left: -42px;
    overflow: visible;
  }

  .actualStep i {
    top: 5px;
  }

  .actualStep .stepDesc {
    display: block;
    margin: auto;
    font-size: 12px;
    margin-top: 3px;
  }

  .imgEsc {
    display: none;
  }

  .labelTitle {
    margin-top: 20px;
  }

  .notActive {
    position: fixed;
    left: 0;
    display: none;
  }

  .title {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    padding: 20px 100px;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .title img {
    display: none;
  }

  .form-solicitud {
    padding: 25px;
  }
}

/*fin movil*/

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .form-c {
    margin-top: 12px;
  }
  .profileInfo hr {
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .title h1 i {
    position: relative;
    top: 3px;
    font-size: 17px;
  }

  .steps div {
    width: 10vw;
    padding: auto;
  }

  .steps label {
    display: none;
  }

  .principal-solicitud {
    display: block;
    margin: 100px 20px;
    width: fit-content;
    box-sizing: border-box;
  }

  .actualStep {
    display: flex;
    align-items: baseline;
  }

  .steps div > div {
    top: -6px;
    left: -33px;
    overflow: visible;
  }

  .actualStep i {
    left: -10px;
    top: 0;
  }

  .actualStep .stepDesc {
    display: flex;
    font-size: 15px;
    position: static;
  }

  .imgEsc {
    display: none;
  }

  .notActive {
    position: fixed;
    left: 0;
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1265px) {
  .steps label {
    display: none;
  }

  .profileInfo hr {
    display: none;
  }

  .stepDesc {
    display: block;
    margin-top: 3px;
  }

  .steps div {
    width: 100%;
  }

  .steps div > div {
    position: relative;
    top: -24px;
    left: 10px;
  }

  .file-content span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 15vw;
    max-width: 90%;
  }
}
