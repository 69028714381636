h2 {
  font-family: var(--fuenteLight);
  color: var(--color-accent);
  font-weight: lighter;
  font-size: 16px;
}
.status-primary {
  color: #00479b !important;
}

.skillsList {
  background-color: #fcfafa;
  border-radius: 10px;
  height: 60px;
  padding: 5px;
  display: block;
  border: none;
}

.divPasatiempos {
  display: flex;
  gap: 25px;
}

.divPasatiempos > div {
  display: flex;
}

.input-large{
  width: 400px !important;
}
.divPasatiempos input {
  position: relative;
  bottom: 6px;
}

.actualizarBtnContainer {
  display: flex;
  margin: 20px 20px 10px 0;
  justify-content: right;
}

.btnActualizar {
  border-radius: 7px;
  background: none;
  border: 1px solid var(--color-accent);
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: var(--color-accent);
  height: min-content;
  margin-right: 10px;
}

.displayBtn .material-icons {
  pointer-events: none;
}

.skillsList li {
  font-family: var(--fuenteLight);
  font-weight: lighter;
  font-size: 13px;
  list-style: none;
  color: #9f9f9f;
}

.mainDivider {
  margin: -15px 0 22px 0;
  width: 95%;
}

label {
  font-family: var(--fuenteLight);
  font-size: 14px;
  margin-bottom: 0;
  font-weight: normal;
}

.dataContainer input,
.dataContainer select {
  border-radius: 10px;
  font-family: var(--fuenteLight);
  font-size: 13.5px;
  height: 24px;
  border: solid 1px rgb(201, 201, 201);
  background: #fcfafa;
  padding-left: 8px;
}

.dataContainer select {
  width: 150px;
  height: 28px;
  display: block;
}

#select-carrera {
  width: 450px;
}

.spanInfo {
  color: rgb(150, 148, 148);
  font-size: 10px;
  font-family: var(--fuenteLight);
  font-weight: lighter;
  margin: 2px 5px;
}

.disabled {
  border: none !important;
  pointer-events: none;
  color: #9f9f9f;
}

.studiesLabel > label {
  color: var(--color-accent);
}

.dataContainer > div {
  margin: 3px 0 0 0;
}

.action-btn {
  display: flex;
  margin-bottom: 8px;
}

.inputsContainer div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 25px 0 0;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 30px;
}

.infoLanguage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.infoLanguage > div {
  display: flex;
  border-bottom: 1px solid #dddddd;
}

.showing .up {
  opacity: 1;
  transform: rotate(360deg);
}

.showing .down {
  opacity: 0;
  transform: rotate(360deg);
}

.up {
  position: relative;
  left: 12px;
  opacity: -1;
  transition: all 1s ease;
}

.down {
  position: relative;
  left: -10px;
  opacity: 1;
  transition: 1s ease all;
}

.invisibleContent + .dataContainer {
  display: none;
  opacity: 0;
  transform: translateY(-10px);
}

.invisibleContent ~ .mainDivider ~ div {
  display: none;
  position: absolute;
  opacity: 0;
  transform: translateY(-40px);
}

.mainContentDetail {
  background-color: white;
  height: auto;
  box-sizing: border-box;
}

.infoLanguage li {
  font-family: var(--fuenteLight);
  font-size: 14px;
}

.divAction {
  display: flex;
  align-items: baseline;
  position: relative;
  justify-content: space-between;
  padding: 0px 30px;
}

.btnGroupAction button {
  font-size: 16px;
  font-family: var(--fuenteBold);
  border-radius: 10px;
  height: 25px;
  padding: 0 10px 10 10px;
}

.mainContentDetail .content {
  margin: auto;
  width: 80%;
}
.mainContentDetail .content-a {
  margin: auto;
  width: 90%;
  margin-top: 10px;
}

.mainContentDetail .content section > div {
  margin-bottom: 4px;
}

.content::-webkit-scrollbar-track {
  background-color: var(--color-bg);
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-variant);
  border-radius: 10px;
}

.content::-webkit-scrollbar {
  width: 10px;
}

.divDisplay {
  display: flex;
}

.divDisplay .mainTitleBtn {
  margin-right: 70%;
  color: var(--color-accent);
  font-family: var(--fuenteLight);
  font-weight: lighter;
}

.divDisplay .mainTitleBtn h3 {
  font-size: 18px;
}
.becas-select {
  width: 50vw !important;
}
.divDisplay .title-btn {
  margin: 0 60% 0 0;
  width: 230px;
}
.divDisplay .title-btn h4 {
  color: var(--color-primary);
  font-family: var(--fuenteLight);
  font-weight: lighter;
  font-size: 15.5px;
  text-align: left;
  margin: 10px 4px;
}

.divDisplay button {
  background: transparent;
  height: fit-content;
  border: none;
  z-index: 1;
}

.divDisplay button .material-icons {
  color: #0e6aea;
  background: white;
}

.mainTitle button .material-icons {
  color: var(--color-accent);
  margin: 10px 0 0 0;
}

.divDisplay + div {
  display: flex;
  flex-wrap: wrap;
  position: static;
  gap: 20px;
  max-width: 90%;
  margin: 20px 50px 70px 30px;
  transition: all 0.5s ease;
  transform: translateY(0px);
  opacity: 1;
}

.divDisplay + div input {
  display: block;
}

.title-btn h4,
.mainTitleBtn h3 {
  pointer-events: none;
}
.mainTitle .displayBtn {
  position: relative;
  top: 20px;
  right: 28px;
}

.btnBlue {
  border: none;
  background-color: var(--color-primary);
  color: white;
  margin-right: 10px;
}

.btnPrimary {
  background: transparent;
  border: 1px solid rgb(14 107 234);
  color: rgb(14 107 234);
  margin-right: 10px;
}
.btnYellow {
  background: transparent;
  border: 1px solid rgb(205, 161, 50);
  color: rgb(205, 161, 50);
  margin-right: 10px;
}

#solicitudEdit {
  background-color: white;
  border: none;
  position: relative;
  top: 7px;
}

.btnOutline {
  background: transparent;
  border: solid 1px #b43232;
  color: #c23c3c;
}

#solicitudEdit .material-icons {
  color: var(--color-primary);
}

#root {
  background-color: white;
}

.inputFile {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.inputFile + .label-ins {
  font-size: 14px;
  font-family: var(--fuenteLight);
  color: gray;
  background-color: #fcfafa;
  display: block;

  border: solid 1px #c1c1c1;
  border-radius: 10px;
  padding: 5px 15px;
}

.subtitulo-solicitud {
  font-family: "circularBook";
  color: var(--color-accent);
}

/*movil*/
@media (max-width: 575.98px) and (orientation: portrait) {
  body {
    overflow-y: scroll;
  }

  #codigo-solicitud {
    font-size: 20px;
  }

  select {
    width: 200px;
  }

  .content {
    width: 100%;
    overflow: visible;
  }

  .divAction {
    width: 90vw;
    flex-wrap: wrap;
  }

  .divAction h2 {
    margin-right: 20px;
  }

  .mainContentDetail {
    width: 100vw;
    display: block;
    box-sizing: border-box;
  }

  .dataContainer {
    display: flex;
    flex-direction: column;
  }

  .dataContainer div {
    margin-top: 5px;
  }

  .infoLanguage > div {
    flex-wrap: wrap;
  }

  .divPasatiempos {
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .mainTitle .mainTitleBtn {
    white-space: nowrap;
    margin-right: 43%;
  }

  .divDisplay .title-btn {
    margin-right: 150px;
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  select {
    width: 200px;
    font-size: 20px;
  }

  .dataContainer div {
    margin-top: 5px;
  }

  .dataContainer .grid-container.col-md-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .infoLanguage > div {
    flex-wrap: wrap;
  }

  .divPasatiempos {
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .content input[type="date"] {
    font-size: 20px;
  }

  .content label,
  .content input[type="text"],
  .content select {
    font-size: 20px;
    width: 200px;
    flex-direction: column;
  }

  .content input[type="radio"],
  .content input[type="checkbox"] {
    width: 20px;
  }

  .divAction {
    flex-wrap: nowrap;
  }

  .divAction h2 {
    font-size: 30px;
  }

  .btnGroupAction {
    flex-wrap: nowrap;
    margin: 0 0 30px 155px;
  }

  .btnGroupAction button {
    height: 34px;
    font-size: 23px;
  }

  .mainContentDetail {
    margin: 0px;
    display: block;
    box-sizing: border-box;
  }

  .mainTitle .mainTitleBtn h3 {
    white-space: nowrap;
    font-size: 27px;
    margin-right: 57%;
  }

  .divDisplay .title-btn {
    margin-right: 380px;
  }
  .divDisplay .title-btn h4 {
    font-size: 20px;
  }

  #solicitudEdit .material-icons {
    font-size: 30px;
  }
}

.card {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
}
.card-body {
  padding: 30px;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.form-submit {
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}

.multiFile-container {
  flex-basis: 100%;
}

.file-container {
  display: flex;
  align-items: center;
  gap: 6px;
}
.file-container img {
  height: 35px;
  width: 35px;
}
.file-container i {
  font-size: 18px;
  color: #b43232;
  cursor: pointer;
}
.file-container .blue {
  font-size: 18px;
  color: #0e69ea;
  cursor: pointer;
}
.file-container a {
  font-size: 16px;
  font-family: var(--fuenteLight);
  color: var(--color-primary);
  font-weight: lighter;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.download-icon {
  font-family: var(--fuenteLight);
  color: var(--color-primary);
  font-weight: lighter;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.file-list {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
