.rootRegistro {
  background-color: white;
  height: 100vh;
}
.rootRegistro .contentRegistro {
  padding: 75px;
  background-color: white;
}

.rootRegistro .toggleBtn {
  display: none !important;
}

.rootRegistro .container {
  padding: 85px 70px;
  display: flex;
  justify-content: center;
}


.rootRegistro h1 {
  font-family: var(--fuenteBold);
  font-size: 44px;
  color: var(--color-accent);
  margin-bottom: 50px;
}

.rootRegistro h2 {
  font-family: var(--fuenteBlack);
  font-size: 34px;
  color: var(--color-accent);
  margin-top: 20px;
}

.rootRegistro label {
  font-family: var(--fuenteLight);
  font-size: 14px;
}

.rootRegistro p {
  margin-top: 1px;
}
.rootRegistro h5 {
  font-family: var(--fuenteLight);
  font-size: 13px;
  font-weight: lighter;
}


.rootRegistro #inputCed {
  margin-bottom: 5px;
  padding-left: 15px;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  width: 130px;
  height: 30px;
  border-style: solid;
}

.rootRegistro #btnConfirm {
  height: 30px;
  width: 95px;
  border-radius: 10px;
  background: var(--color-bg-variant);
  font-family: var(--fuenteBold);
  color: var(--color-accent);
  border: 0ch;
  font-size: 16px;
  margin-left: 5px;
}

.rootRegistro #inputNombre {
  width: 314px;
}

.rootRegistro #inputApellido {
  width: 314px;
}

.rootRegistro #inputTel {
  width: 130px;
}

.rootRegistro #btnRegistro {
  margin-top: 16px;
  padding-top: 4px;
  background: var(--color-primary);
  font-family: var(--fuenteBold);
  font-size: 16px;
  color: white;
  width: 230px;
  height: 30px;
  border-radius: 10px;
  border: 0ch;
  cursor: pointer;
}

.rootRegistro .input {
  display: block;
  padding-left: 15px;
  margin-bottom: 10px;
  margin-top: 3px;
  width: 256px;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  height: 30px;
  border-style: solid;
}

.registerFlex {
  display: flex;
}

#inputCorreo {
  max-width: 200px;
}

/* tablet */
@media (min-width: 576px) and (max-width: 991.98px) {
  .rootRegistro .container {
    flex-wrap: wrap;
  }

  .rootRegistro .imgRegistro {
    width: 340px;
    margin-left: 20px;
    margin-top: 70px;
    margin-bottom: 30px;
  }

  .rootRegistro .inputsContainer {
    display: grid;
    justify-items: center;
  }
  .rootRegistro .nav {
    display: flex;
    opacity: initial;
  }

  .rootRegistro .nav-ul {
    color: white;
    display: flex;
    align-items: center;
  }
}

/*fin tablet*/

/*movil*/

@media (max-width: 575.98px) {
  .rootRegistro .container {
    flex-wrap: wrap;
  }

  .rootRegistro .contentRegistro {
    padding: 60px 25px;
  }
  .rootRegistro h1 {
    text-align: center;
    font-size: 33px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .rootRegistro .imgRegistro {
    width: 230px;
    font-display: block;
    margin: auto;
  }

  .rootRegistro .inputsContainer {
    display: grid;
    justify-items: center;
  }

  .rootRegistro h2 {
    font-family: var(--fuenteLight);
    font-size: 26px;
  }

  .rootRegistro .nav ul li,
  .rootRegistro ul {
    font-size: 11.5px;
    padding: 3px 8px 3px 8px;
  }

  .rootRegistro .nav {
    display: flex;
    opacity: initial;
    justify-content: center;
  }

  .rootRegistro .nav-ul {
    color: white;
    display: flex;
    align-items: center;
  }

  .rootRegistro .nav-ul li {
    font-size: 13px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) {
  .rootRegistro h1 {
    font-size: 33px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .rootRegistro h2 {
    font-size: 26px;
  }
  .rootRegistro .nav {
    display: flex;
    opacity: initial;
  }

  .rootRegistro .nav-ul {
    color: white;
    display: flex;
    align-items: center;
  }

  .rootRegistro .nav-ul li {
    font-size: 14px;
  }
}

/*fin movil*/
