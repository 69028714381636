body {
  background: var(--color-bg);
  min-height: 100%;
}

.rootEdit h1 {
  font-family: var(--fuenteBold);
  font-size: 26px;
  color: var(--color-primary);
  white-space: nowrap;
}

.rootEdit h2 {
  font-family: var(--fuenteBold);
  font-size: 24px;
  color: var(--color-primary);
  margin-bottom: 10px;
  margin-top: -20px;
}

.rootEdit hr {
  margin-top: -35px;
  margin-bottom: 20px;
}

.rootEdit .nav-inf {
  padding-top: 35px;
  padding-bottom: 7px;
  background: var(--color-bg);
  height: 70px;
  display: flex;
  position: fixed;
  top: 40px;
  left: 0;
  right: 255px;
  z-index: 1;
}

.rootEdit .nav-inf img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.rootEdit .imgMovil {
  display: none;
}

.rootEdit .title {
  background-color: white;
  padding-left: 30px;
  padding-top: 5px;
  width: 2500px;
  border-radius: 10px;
  display: flex;
  gap: 40%;
  position: relative;
  right: 32px;
}

.rootEdit .title i {
  color: var(--color-primary);
}

.rootEdit .menuPerfil {
  background: white;
  position: fixed;
  width: 255px;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.rootEdit .menuPerfil>* {
  margin-top: 50px;
}

.rootEdit .menuPerfil label {
  font-family: var(--fuenteLight);
  font-size: 15px;
  color: var(--color-primary);
  margin-top: 5px;
  display: block;
  text-align: left;
}

.rootEdit .menuPerfil label i {
  color: var(--color-secondary);
  margin-right: 15px;
  position: relative;
  top: 5px;
}

.rootEdit .menuPerfil i {
  color: var(--color-primary);
  margin: 5px 5px;
}

.rootEdit .menuPerfil h2 {
  color: var(--color-accent);
  margin-top: 20px;
}

.rootEdit .profileInfo {
  margin-left: 40px;
  margin-right: 20px;
}

#inputImageProfile{
  width: 150px;
opacity: 0;
  position: absolute;
  cursor: pointer;
}

#file-upload-button{
cursor: pointer;
}

.img-perfil{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px var(--color-bg-variant);
}

.btn-quit-image{
  border-radius: 50%;
  border: none;
  background-color: var(--color-bg);
height: 40px;
width: 40px;
color:var(--color-accent);
position :absolute;
top:0;
right: 0;
}


.btn-load-image{
  cursor: pointer;
  font-size: 14px;
  font-family: var(--fuenteMedium);
  color: gray;
  background-color: #fcfafa;
  display: block;
  width: 150px;
  height: 30px;
  border: solid 1px #c1c1c1;
  border-radius: 10px;
  padding: 0 15px;
}

/* .rootEdit #btnEdit {
  background: var(--color-primary);
  color: white;
  font-family: var(--fuenteMedium);
  font-size: 16px;
  border-radius: 10px;
  padding: 5px 20px;
  position: relative;
  bottom: -35px;
} */

.rootEdit #profileImg {
  border-radius: 100px;
  width: 119px;
}

.rootEdit form {
  padding: 50px;
}

.rootEdit #inputPhoto {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.rootEdit select {
  display: block;
  padding-left: 15px;
  margin-bottom: 10px;
  background: hsl(0, 25%, 98%);
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  width: 185px;
  height: 35px;
}

.rootEdit .container-root {
  height: auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
}

.rootEdit .inputsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: var(--fuenteLight);
  font-size: 14px;
}

.rootEdit .inputsContainer div {
  margin: 0 30px 0 0;
}

.rootEdit .toggleBtnEdit i {
  font-size: 25px;
  color: var(--color-primary);
}

.rootEdit .toggleBtnEdit {
  display: none;
  padding: 18px 0;
  border: none;
  background: transparent;
  position: fixed;
  right: 15px;
  top: 0;
}

.rootEdit .imgForm {
  height: 230px;
  margin-bottom: 20px;
}

.rootEdit .toggleBlueEdit i {
  color: var(--color-primary);
}

/*movil''''''''''''''''''''''''''''''''''*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .rootEdit h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .rootEdit form {
    flex-direction: column;
    padding: 25px;
  }

  /* .rootEdit form label,
  .rootEdit form input {
    margin-bottom: 20px;
  } */

  .rootEdit .title h1 i {
    position: relative;
    top: 3px;
    font-size: 17px;
  }

  .rootEdit .nav-inf {
    height: 60px;
    padding: 0;
    width: 100%;
    top: 0;
  }

  .rootEdit .nav-inf img {
    width: 2.5rem;
    position: absolute;
    left: 0;
    right: 500px;
    top: 0;
    z-index: 1;
    margin-left: 1.2rem;
  }

  .rootEdit .principal {
    display: block;
    margin-left: auto;
    margin-top: 100px;
    margin-right: auto;
    width: fit-content;
  }

  .rootEdit .container-root {
    width: 90vw;
    height: fit-content;
    padding: 0;
  }

  .rootEdit .container-root a {
    left: 0px;
    bottom: 0;
  }

  .rootEdit .imgMovil {
    display: flex;
  }

  .rootEdit .imgEsc {
    display: none;
  }

  .rootEdit .imgForm {
    position: static;
    width: 100%;
  }

  .rootEdit .toggleBtnEdit {
    display: flex;
    z-index: 2;
  }

  .rootEdit .notActive {
    position: fixed;
    left: 0;
    display: none;
  }

  .rootEdit .menuPerfil {
    width: 100%;
    z-index: 1;
    padding-top: 1%;
  }

  .rootEdit .menuPerfil label {
    text-align: center;
    margin-left: -10px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .rootEdit .title {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    padding: 20px 100px;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .rootEdit .title img {
    display: none;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .rootEdit h1 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .rootEdit form {
    flex-direction: column;
    padding: 60px;
  }
/* 
  .rootEdit form label,
  .rootEdit form input {
    margin-bottom: 20px;
  } */

  .rootEdit .title h1 i {
    position: relative;
    top: 3px;
    font-size: 17px;
  }

  .rootEdit .nav-inf {
    height: 60px;
    padding: 0;
    width: 100%;
    top: 0;
  }

  .rootEdit .nav-inf img {
    width: 2.5rem;
    position: absolute;
    left: 0;
    right: 500px;
    top: 0;
    z-index: 1;
    margin-left: 1.2rem;
  }

  .rootEdit .principal {
    display: block;
    margin-left: auto;
    margin-top: 100px;
    margin-right: auto;
    width: fit-content;
  }

  .rootEdit .container-root {
    width: 90%;
    margin: 50px;
    height: fit-content;
    padding: 0;
  }

  .rootEdit .container-root a {
    left: 42%;
    bottom: 50px;
  }

  .rootEdit .imgMovil {
    display: flex;
  }

  .rootEdit .imgEsc {
    display: none;
  }

  .rootEdit .imgForm {
    position: relative;
    left: 180px;
    width: 100%;
  }

  .rootEdit .toggleBtnEdit {
    display: flex;
    z-index: 2;
  }

  .rootEdit .notActive {
    position: fixed;
    left: 0;
    display: none;
  }

  .rootEdit .menuPerfil {
    width: 28%;
    zoom: 70%;
    overflow: scroll;
    z-index: 1;
    padding-top: 1%;
  }

  .rootEdit .menuPerfil label {
    text-align: center;
    margin-left: -45px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .rootEdit .title {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    padding: 20px 100px;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .rootEdit .title img {
    display: none;
  }
}

/*fin movil*/

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .rootEdit #inputEspec {
    width: 100px;
    left: 100px;
  }


  .rootEdit #inputAlergia {
    width: 200px;
    left: -60px;
  }

  .rootEdit #inputCel {
    margin-left: 0;
  }

  .rootEdit #inputMed {
    width: 200px;
  }

  .rootEdit #inpuEmergencytContact {
    width: 200px;
  }

  .rootEdit h1 {
    font-size: 26px;
    margin: 12px 10px 0 10px;
  }

  .rootEdit form {
    flex-direction: column;
    margin: auto;
  }

  .rootEdit .title h1 i {
    position: relative;
    top: 2px;
  }

  .rootEdit .principal {
    width: 90%;
    height: 100vh;
    margin: 150px auto;
  }

  .rootEdit .container-root {
    width: 100%;
    /* height: 80%; */
    box-sizing: border-box;
    padding: 5%;
  }

  .rootEdit .container-root a {
    left: 0;
    bottom: 35px;
    font-size: 20px;
  }

  .rootEdit .imgMovil {
    display: flex;
  }

  .rootEdit .imgEsc {
    display: none;
  }

  .rootEdit .imgForm {
    margin-top: 150px;
    bottom: 10px;
  }

  .rootEdit .toggleBtnEdit i {
    font-size: 32px;
  }

  .rootEdit .toggleBtnEdit {
    display: flex;
    z-index: 3;
    top: 1px;
  }

  .rootEdit .notActive {
    position: fixed;
    left: 0;
    display: none;
  }

  .rootEdit .menuPerfil {
    width: 100%;
    z-index: 1;
    padding-top: 4%;
  }

  .rootEdit .menuPerfil label {
    text-align: center;
    margin-left: -45px;
    font-size: 24px;
    margin-bottom: 35px;
  }

  .rootEdit .menuPerfil h2 {
    font-size: 30px;
  }

  .rootEdit .menuPerfil img {
    width: 200px !important;
  }

  .rootEdit .menuPerfil i {
    font-size: 35px;
  }

  .rootEdit #btnEdit {
    font-size: 24px;
  }

  .rootEdit .nav-inf {
    height: 75px;
    padding: 0;
    width: 100%;
    top: 0;
  }

  .rootEdit .nav-inf img {
    width: 3.5rem;
    position: absolute;
    left: 0;
    right: 500px;
    top: 0;
    z-index: 1;
    margin-left: 1.2rem;
  }

  .rootEdit .title {
    object-fit: contain;
    position: relative;
    margin: 0;
    left: 0;
    padding: 10px 140px;
    border-radius: 0;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .rootEdit .title img {
    display: none;
  }
}

/*fin tablet*/