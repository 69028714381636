.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: 0.5s ease all;
  opacity: 0;
  z-index: 1000;
}

.overlay.active {
  visibility: visible;
  opacity: 1;
}

.modalContainer {
  width: fit-content;
  height: fit-content;
  background: white;
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 8px 7px 29px 8px;
  transition: 0.5s ease all;
  transform: scale(0.5);
  opacity: 0;
}

.modalContainer select,
.modalContainer input {
  width: 100%;
  display: block;
}

.modalContainer .table-container {
  width: auto;
}

.modalContainer input[type="checkbox"i] {
  margin: -2px 10px;
  width: min-content;
}

.modalHeader {
  display: block;
  font-family: var(--fuenteMedium);
  margin: 0;
  padding: 1.25rem 1.5rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.header-title {
  font-size: 1.3rem;
}

.min-height {
  min-height: 400px;
}
.min-width {
  min-width: 300px;
}

.modal-body {
  display: block;
  width: initial;
  font-size: 1em;
  max-height: 405px;
  overflow-y: scroll;
  padding: 1.5rem;
  display: block;
}

.modal-body::-webkit-scrollbar-track {
  background-color: rgb(254, 254, 254);
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar {
  width: 10px;
}

.modal-actions {
  padding: 0.6rem 0.6rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}

.overlay.active .modalContainer,
.overlay.active .modalRemoveContainer {
  transform: scale(1);
  opacity: 1;
}

.modalRegisterName {
  font-size: 22px;
  width: 450px;
  border-radius: 10px;
  padding-left: 10px;
  margin-left: -10px;
  border: 1px solid gainsboro;
}

.modalTitle {
  background: none;
  border: none;
  pointer-events: none;
}

.modalFlex {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.modalFlexRemove {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.btnCloseModal {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  border-radius: 5px;
}

.btnCloseModal .material-icons {
  color: gray;
}

.editorContainerModal,
.editorContainerModal .note-editor,
.divEditorModal textarea {
  width: 100%;
  box-sizing: content-box;
}

.note-editor.note-airframe .note-editing-area,
.note-editor.note-frame .note-editing-area {
  overflow: hidden;
  max-height: 100%;
}

.note-editor.note-frame .note-editing-area .note-editable {
  padding: 10px;
  word-wrap: break-word;
  background: white;
}

.modalRemoveContainer {
  width: 25vw;
  height: fit-content;
  background: white;
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 8px 7px 29px 8px;
  padding: 20px 35px;
  transition: 0.5s ease all;
  transform: scale(0.5);
  opacity: 0;
}

.warning {
  color: rgb(255, 233, 121);
  font-size: 50px !important;
  position: relative;
  bottom: 5px;
  margin-right: 10px;
}

.material-icons.info {
  color: var(--color-secondary);
  font-size: 50px !important;
  position: relative;
  bottom: 5px;
  margin-right: 10px;
}

.btnConfirm {
  color: rgb(217, 103, 103);
  border: 1px solid rgb(217, 103, 103);
  font-family: var(--fuenteMedium);
  background: transparent;
  border-radius: 7px;
  margin-top: 30px;
  margin-left: 165px;
}

.btnCancelOp {
  color: gray;
  border: 1px solid gray;
  font-family: var(--fuenteMedium);
  border-radius: 7px;
  margin-left: 10px;
  margin-top: 30px;
}

.remove-item {
  font-size: 18px;
  font-family: var(--fuenteLight);
  color: #e74c3c;
}

.activate-item {
  font-size: 18px;
  font-family: var(--fuenteLight);
  color: var(--color-accent);
}

.flex-form {
  display: flex;
  align-items: center;
}

.flex-form.gap-20 {
  gap: 30px;
  align-items: flex-start;
  margin-top: 20px;
}

.flex-form input,
.flex-form select {
  width: 50%;
}

/*movil*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .modalTitle {
    font-size: 15px;
    font-family: var(--fuenteBold);
  }

  .overlay {
    padding: 0;
  }

  .flex-form {
    flex-wrap: wrap;
  }

  .editorContainerModal div {
    margin-bottom: 0;
  }

  .editorContainerModal,
  .editorContainerModal .note-editor,
  .divEditorModal textarea {
    zoom: 90%;
    width: 100%;
  }

  .modalRemoveContainer {
    width: 65vw;
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .flex-form {
    flex-wrap: wrap;
  }
}
